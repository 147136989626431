import { motion, useScroll, useTransform } from 'framer-motion'
import React, { useRef } from 'react'
import PropTypes from 'prop-types';


function OverlayCopy({ subheading, heading }) {
  const targetRef = useRef(null)
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ['start end', 'end start'],
  })

  const y = useTransform(scrollYProgress, [0, 1], [250, -250])
  const opacity = useTransform(scrollYProgress, [0.25, 0.5, 0.75], [0, 1, 0])

  return (
    <motion.div
      style={{
        y,
        opacity,
      }}
      ref={targetRef}
      className="absolute left-0 top-[-50px] flex h-screen w-full flex-col items-center justify-center text-white"
    >
      <p className="mb-2 text-center text-xl font-bold text-redPrimary md:mb-4 md:text-3xl">
        {subheading}
      </p>
      <p className="text-center font-title text-3xl font-bold md:text-4xl">
        {heading}
      </p>
    </motion.div>
  )
}
OverlayCopy.propTypes = {
  subheading: PropTypes.string.isRequired,   // La prop "subheading" est requise et doit être une chaîne de caractères
  heading: PropTypes.string.isRequired,      // La prop "heading" est requise et doit être une chaîne de caractères
  backgroundColor: PropTypes.string,         // La prop "backgroundColor" est optionnelle et doit être une chaîne de caractères
};
export default OverlayCopy
