import React from 'react'
import { logos } from '../constants/constant'
function Partners() {
  return (
    <div className="h-auto w-full px-[5%] pb-20 py-10 bg-white">
      <h1 className="text-center text-2xl sm:text-3xl font-title font-bold text-redPrimary w-full mb-16">
        Partenaires / <span className="text-black">Références</span>
      </h1>

      {/* Partner Logos Section */}
      <div className="grid grid-cols-2 bg-white  sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6">
        {logos.map((logo, index) => (
          <div key={index} className="flex justify-center ">
            <img
              src={logo}
              alt={`Partner ${index + 1}`}
              className="w-24 h-24 sm:w-28 sm:h-28 object-cover  animate-fadeInDown"
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Partners
