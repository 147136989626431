import React from 'react';
import StickyImage from './StickyImage'
import OverlayCopy from './OverlayCopy'
import PropTypes from 'prop-types';

function TextParallaxContent({ imgUrl, subheading, heading, children }) {
  return (
    <div>
      <div className="relative h-[150vh]">
        <StickyImage imgUrl={imgUrl} />
        <OverlayCopy heading={heading} subheading={subheading} />
      </div>
      {children}
    </div>
  )
}
TextParallaxContent.propTypes = {
  imgUrl: PropTypes.string.isRequired,  // 'imgUrl' doit être une chaîne de caractères et est obligatoire
  subheading: PropTypes.string,         // 'subheading' est une chaîne de caractères et est optionnelle
  heading: PropTypes.string.isRequired, // 'heading' doit être une chaîne de caractères et est obligatoire
  children: PropTypes.node,             // 'children' peut être n'importe quel type de contenu React (texte, composants, etc.)
};
export default TextParallaxContent
