import React from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'

function ActivitiesSection({ page }) {
  return (
    <div
      className={`h-auto mx-auto p-[2px] ${
        page === 'home' ? 'bg-redSecondary w-full' : 'w-[90%]'
      }`}
      id="activities"
    >
      {page === 'home' && (
        <h1 className="section-title text-center text-2xl sm:text-3xl font-title font-bold text-white w-full px-auto py-12 sm:py-20 bg-[#050507] relative">
          Domaines d’intervention
        </h1>
      )}
      <div className="my-14 mx-2 sm:mx-12">
        {/* Card 1 */}
        <div className="bg-white shadow-lg rounded-md mb-16 overflow-hidden flex flex-col sm:flex-row">
          <div className="sm:w-2/3 h-full ">
            <img
              src="/images/hotellerie.jpg"
              alt="hotellerie"
              className="w-full"
            />
          </div>
          <div className="sm:w-1/3 p-6 flex flex-col justify-center rounded-md">
            <h2 className="text-2xl font-title font-semibold text-redPrimary mb-4 border-b border-retext-redPrimary pb-1">
              Hotellerie
            </h2>
            <p className="text-gray-600 mb-4">
              Fort de notre experience dans l’amenagement hôtelier, confier nous
              vos rêves pour qu’ils deviennent réalité... Des solutions
              tendance, design et moderne procurant une ambiance unique et
              conviviale à vos projets donant vie à vos idées.
            </p>
            <Link
              to="/gallery?category=Hospitalité"
              className="px-6 py-2 sm:w-[80%] bg-redPrimary text-white rounded-md hover:bg-[#b06f6b] transition duration-200 text-center"
            >
              Visiter Galerie
            </Link>
          </div>
        </div>
        {/* Card 2 */}
        <div className="bg-white shadow-lg rounded-md mb-16 overflow-hidden flex flex-col-reverse sm:flex-row">
          <div className="sm:w-1/3 p-6 flex flex-col justify-center rounded-md">
            <h2 className="text-2xl font-title font-semibold text-redPrimary mb-4 border-b border-retext-redPrimary pb-1">
              Bureau et Commerce
            </h2>
            <p className="text-gray-600 mb-4">
              Ensemble, étudions un espace de travail afin d’optimiser la
              productivité des équipes en leur procurant un environnement
              agréable et propice au bien être qui accroîtra leurs motivations.
              Nous vous accompagnons à résoudre vos problématiques design et
              technique qui mettrons en avant show-room, magasin et tout autre
              espace d’accueil.
            </p>
            <Link
              to="/gallery?category=Bureau et Commerce"
              className="px-6 py-2 sm:w-[80%] bg-redPrimary text-white rounded-md hover:bg-[#b06f6b] transition duration-200 text-center"
            >
              Visiter Galerie
            </Link>
          </div>
          <div className="sm:w-2/3 h-full ">
            <img
              src="/images/bureau_commerce.jpg"
              alt="hotellerie"
              className="w-full"
            />
          </div>
        </div>
        {/* Card 3 */}
        <div className="bg-white shadow-lg rounded-md overflow-hidden flex flex-col sm:flex-row">
          <div className="sm:w-2/3 h-full ">
            <img
              src="/images/habitation.jpg"
              alt="hotellerie"
              className="w-full"
            />
          </div>
          <div className="sm:w-1/3 p-6 flex flex-col justify-center rounded-md">
            <h2 className="text-2xl font-title font-semibold text-redPrimary mb-4 border-b border-retext-redPrimary pb-1">
              Habitation
            </h2>

            <p className="text-gray-600 mb-4">
              Avec notre expertise dans l&apos;aménagement résidentiel, nous
              transformons vos espaces de vie en véritables havres de paix. Que
              ce soit pour une maison, un appartement ou toute autre forme
              d&apos;habitation, nous vous offrons des solutions modernes,
              confortables et personnalisées, créant une ambiance chaleureuse et
              accueillante qui répond à vos besoins.
            </p>
            <Link
              to="/gallery?category=Habitation"
              className="px-6 py-2 sm:w-[80%] bg-redPrimary text-white rounded-md hover:bg-[#b06f6b] transition duration-200 text-center"
            >
              Visiter Galerie
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
ActivitiesSection.propTypes = {
  page: PropTypes.string,  // 'page' doit être une chaîne de caractères et est obligatoire
};

export default ActivitiesSection
