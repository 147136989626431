import React from 'react'
import Slider from 'react-slick' // Import react-slick
import { images, settingsEnv } from '../constants/constant'
function EnvironmentalSection() {
  return (
    <div className="enviromental h-auto w-full bg-white px-[5%] py-12 ">
      <h1 className="text-center text-2xl font-title sm:text-3xl font-bold text-redPrimary w-full mb-10">
        Démarche environnementale
      </h1>
      <p className="text-center text-black mb-8">
        Vous devez soit modifier vos rêves ou améliorer vos compétences
      </p>

      {/* Carousel using react-slick */}
      <Slider {...settingsEnv}>
        {images.map((image, index) => (
          <div
            key={index}
            className="flex justify-center items-center w-28 h-28  p-2 m-2 overflow-hidden "
          >
            <img
              src={image}
              alt={`Environmental ${index + 1}`}
              className="w-full h-full object-contain"
            />
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default EnvironmentalSection
