import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link' // Import HashLink
import { navLinks } from '../constants/constant'

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()

  return (
    <nav className="sticky top-0 left-0 z-50 py-1 bg-[#050507] border-b border-neutral-700/80 text-white relative">
      <div className="w-full sm:w-[90%]  mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <Link
            to="/"
            className="flex items-center w-36 sm:w-44 cursor-pointer"
          >
            <img
              src="/images/logo-primary.svg"
              alt="DeltaSign Logo"
              className="h-full w-full"
            />
          </Link>

          {/* Menu for larger screens */}
          <div className="hidden md:flex  space-x-6">
            {navLinks.map(({ path, label }) =>
              path.startsWith('/#') ? (
                <HashLink
                  smooth
                  to={path}
                  key={path}
                  className={`hover:text-redPrimary ${
                    location.pathname + location.hash === path
                      ? 'text-redPrimary'
                      : ''
                  }`}
                >
                  {label}
                </HashLink>
              ) : (
                <Link
                  to={path}
                  key={path}
                  className={`hover:text-redPrimary ${
                    location.pathname === path ? 'text-redPrimary' : ''
                  }`}
                >
                  {label}
                </Link>
              ),
            )}
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-white hover:text-redPrimary focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={
                    isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'
                  }
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Dropdown menu for mobile */}
      {isOpen && (
        <div className="absolute top-16 left-0 right-0 backdrop-blur-lg bg-[#050507] border-b border-neutral-700/80 shadow-lg">
          <div className="pb-4 ml-4 sm:ml-6 space-y-2">
            {navLinks.map(({ path, label }) =>
              path.startsWith('/#') ? (
                <HashLink
                  smooth
                  to={path}
                  onClick={() => setIsOpen(false)}
                  key={path}
                  className={`block px-4 py-2 hover:text-redPrimary ${
                    location.pathname + location.hash === path
                      ? 'text-redPrimary'
                      : ''
                  }`}
                >
                  {label}
                </HashLink>
              ) : (
                <Link
                  to={path}
                  onClick={() => setIsOpen(false)}
                  key={path}
                  className={`block px-4 py-2 hover:text-redPrimary ${
                    location.pathname === path ? 'text-redPrimary' : ''
                  }`}
                >
                  {label}
                </Link>
              ),
            )}
          </div>
        </div>
      )}
    </nav>
  )
}

export default Navbar
