import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
function PortfolioSection() {
  const [count, setCount] = useState(0)
  const [isCounting, setIsCounting] = useState(false)
  const portfolioRef = useRef(null)

  useEffect(() => {
    const handleScroll = (entries) => {
      const [entry] = entries
      if (entry.isIntersecting) {
        setIsCounting(true)
      }
    }

    const observer = new IntersectionObserver(handleScroll, {
      threshold: 0.3,
    })
    let current = portfolioRef.current
    if (current) {
      observer.observe(current)
    }

    return () => {
      if (current) {
        observer.unobserve(current)
      }
    }
  }, [])

  useEffect(() => {
    if (!isCounting) return

    const target = 400
    const duration = 3000
    const increment = target / (duration / 10)

    const counter = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount >= target) {
          clearInterval(counter)
          return target
        }
        return prevCount + increment
      })
    }, 10)

    return () => clearInterval(counter)
  }, [isCounting])

  return (
    <div ref={portfolioRef} className="h-auto w-[90%] mx-auto ">
      <h1 className="text-center font-title text-2xl sm:text-3xl font-bold text-redPrimary w-full mb-10">
        Découvrez notre portfolio
      </h1>
      <p className="text-center font-title text-2xl font-semibold bg-redSecondary text-white shadow-lg  sm:w-[30%] w-[60%] mx-auto py-4">
        +{Math.round(count)} clients
      </p>

      {/* Cards Section */}
      <div className="mt-10 grid grid-cols-1 sm:grid-cols-3 gap-6">
        <div className="bg-white shadow-lg  p-6 flex flex-col items-center">
          <img
            src="/images/portfolio1.jpg"
            alt="Project 1"
            className="w-full h-[250px] sm:h-[300px] object-cover rounded mb-4"
          />
          <h3 className="text-xl font-title font-semibold text-redPrimary">
            Unilever-El-Mohammadia- ALGERIE
          </h3>
        </div>
        <div className="bg-white shadow-lg  p-6 flex flex-col items-center">
          <img
            src="/images/portfolio2.jpg"
            alt="Project 2"
            className="w-full h-[250px] sm:h-[300px] object-cover rounded mb-4"
          />
          <h3 className="text-xl font-title font-semibold text-redPrimary">
            Ramsec - Montreuil - FRANCE
          </h3>
        </div>
        <div className="bg-white shadow-lg  p-6 flex flex-col items-center">
          <img
            src="/images/portfolio3.jpg"
            alt="Project 3"
            className="w-full h-[250px] sm:h-[300px] object-cover rounded mb-4"
          />
          <h3 className="text-xl font-title font-semibold text-redPrimary">
            Sheraton - Oran - ALGERIE
          </h3>
        </div>

        {/* Second Row */}
        <div className="bg-white shadow-lg  p-6 flex flex-col items-center sm:col-span-2">
          <img
            src="/images/portfolio4.jpg"
            alt="Project 4"
            className="w-full h-[250px] sm:h-[300px] object-cover rounded mb-4"
          />
          <h3 className="text-xl font-title font-semibold text-redPrimary">
            Atlantis - Bejaia - ALGERIE
          </h3>
        </div>
        <div className="bg-white shadow-lg  p-6 flex flex-col items-center">
          <img
            src="/images/portfolio5.jpg"
            alt="Project 5"
            className="w-full h-[250px] sm:h-[300px] object-cover rounded mb-4"
          />
          <h3 className="text-xl font-title font-semibold text-redPrimary">
            Sheraton Four Point - Sétif - ALGERIE
          </h3>
        </div>
      </div>

      <div className="mt-10 flex justify-center">
        <Link
          to="/carousel"
          className="px-6 py-3 bg-redptext-redPrimary text-white font-semibold rounded-md hover:bg-redSecondary transition duration-200"
        >
          Voir Plus
        </Link>
      </div>
    </div>
  )
}

export default PortfolioSection
