import { motion, useScroll, useTransform } from 'framer-motion'
import React, { useRef } from 'react'
import PropTypes from 'prop-types';


function StickyImage({ imgUrl }) {
  const targetRef = useRef(null)
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ['end end', 'end start'],
  })

  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85])
  const opacity = useTransform(scrollYProgress, [0, 1], [1, 0])

  return (
    <motion.div
      style={{
        backgroundImage: `url(${imgUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: `calc(100vh - ${12 * 2}px)`,
        top: 12,
        scale,
      }}
      ref={targetRef}
      className="sticky z-0 overflow-hidden "
    >
      <motion.div
        className="absolute inset-0 bg-neutral-950/70"
        style={{
          opacity,
        }}
      />
    </motion.div>
  )
}
StickyImage.propTypes = {
  imgUrl: PropTypes.string.isRequired,  // imgUrl est requise et doit être une chaîne
  altText: PropTypes.object,  // altText est requise et doit être une chaîne
};
export default StickyImage
