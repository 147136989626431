import React from 'react'
// import ServicesTop from '../components/ServicesTop'
import ServiceContent from '../components/ServiceContent'
import ServiceCarousel from '../components/ServiceCarousel'
import ActivitiesSection from '../components/ActivitiesSection'

function Services() {
  return (
    <div>
      {/* <ServicesTop /> */}
      <h1 className="sm:text-5xl text-3xl font-title mx-1 text-redPrimary text-center mt-32 mb-6  animate-fadeInDown sm:mt-40 font-bold">
        Notre expertise,<span className="text-white"> votre vision</span>{' '}
      </h1>
      <p className="text-center text-white mx-2 mb-20 text-lg">
        Découvrez nos domaines d&apos;intervention
      </p>
      <ActivitiesSection />
      <ServiceContent />
      <ServiceCarousel />
    </div>
  )
}

export default Services
