import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { imagesM, imagesP, settings } from '../constants/constant'

function ServiceCarousel() {
  return (
    <>
      <div className="service-carousel h-auto w-[90%] mx-auto my-12 py-6">
        <h1 className="text-center text-xl sm:text-2xl font-bold text-white w-full mb-10">
          Matériel de nettoyage
        </h1>

        {/* Carousel using react-slick */}
        <Slider {...settings}>
          {imagesM.map((image, index) => (
            <div
              key={index}
              className="flex justify-center items-center w-[250px] h-[450px] p-2 m-2 overflow-hidden border border-black rounded-lg"
            >
              <img
                src={image}
                alt={`Service ${index + 1}`}
                className="w-full h-full object-cover" // Ensures the image fills the container with cover
              />
            </div>
          ))}
        </Slider>
      </div>
      <div className="service-carousel h-auto w-[90%] mx-auto my-12 py-6">
        <h1 className="text-center text-xl sm:text-2xl font-bold text-white w-full mb-10">
          Produits de nettoyage
        </h1>

        {/* Carousel using react-slick */}
        <Slider {...settings}>
          {imagesP.map((image, index) => (
            <div
              key={index}
              className="flex justify-center items-center w-[250px] h-[450px] p-2 m-2 overflow-hidden border border-black rounded-lg"
            >
              <img
                src={image}
                alt={`Service ${index + 1}`}
                className="w-full h-full object-cover" // Ensures the image fills the container with cover
              />
            </div>
          ))}
        </Slider>
      </div>
    </>
  )
}

export default ServiceCarousel
