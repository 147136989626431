import React from 'react'
import TextParallaxContent from '../components/TextParallaxContent'
import AboutContent from '../components/AboutContent'
import Image1 from '../assets/images/about1.jpg'
import Image2 from '../assets/images/habitation.jpg'
import Image3 from '../assets/images/about3.jpg'
import Image4 from '../assets/images/hotellerie.jpg'
import Image5 from '../assets/images/about2.jpg'
import Image6 from '../assets/images/portfolio2.jpg'

function About() {
  return (
    <div>
      <TextParallaxContent
        imgUrl={Image1}
        subheading="QUALITE"
        heading="Un engagement envers la qualité exceptionnelle."
      >
        <AboutContent
          imgUrl={Image4}
          text="Chez DELTASIGN, la qualité est notre priorité. Nous sélectionnons des matériaux de haute durabilité, avec des certifications internationales. Notre engagement vous garantit des produits jusqu'à 10 ans."
          reverse={false}
        />
      </TextParallaxContent>

      <TextParallaxContent
        imgUrl={Image2}
        subheading="REACTIVITE"
        heading="Une réponse rapide à vos besoins et demandes."
      >
        <AboutContent
          imgUrl={Image5}
          text="Nous assurons une réactivité sans faille à chaque étape de votre projet. Nos experts adaptent leurs solutions pour répondre précisément et rapidement à vos attentes. L'inspiration n’a pas de limite."
          reverse={true}
        />
      </TextParallaxContent>

      <TextParallaxContent
        imgUrl={Image3}
        subheading="RESPONSABILITE"
        heading="Une prise de responsabilité dans chaque projet."
      >
        <AboutContent
          imgUrl={Image6}
          text="Chez DELTASIGN, la responsabilité guide nos choix. Nous privilégions des solutions durables, respectueuses de l’environnement. Votre satisfaction et notre engagement sont au cœur de notre démarche."
          reverse={false}
        />
      </TextParallaxContent>
    </div>
  )
}

export default About
