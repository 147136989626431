import React, { useEffect, useState } from 'react'
import ActivitiesSection from '../components/ActivitiesSection'
import ContactSection from '../components/ContactSection'
import EnvironmentalSection from '../components/EnvironmentalSection'
import HeroSection from '../components/HeroSection'
import NewsLetter from '../components/NewsLetter'
import PartnersSection from '../components/PartnersSection'
import Popup from '../components/Popup'
import PortfolioSection from '../components/PortfolioSection'
import PresentationSection from '../components/PresentationSection'
import SectionDivider from '../components/SectionDivider'
 

function Home() {
  const [isPopupVisible, setIsPopupVisible] = useState(false)
  const [popupData, setPopupData] = useState(null)

  useEffect(() => {
    // Check if popup is already been shown that day
    const popupShownDate = localStorage.getItem('popupShown')
    const currentDate = new Date()
    const oneDay = 24 * 60 * 60 * 1000 // One day in mS : hours*minutes*seconds*milliseconds

    if (!popupShownDate || currentDate - new Date(popupShownDate) > oneDay) {
      // Fetch popup data from the backend
      const fetchPopupData = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/media/popup/active`,
          )
          if (!response.ok) {
            throw new Error('Failed to fetch popup data')
          }
          const data = await response.json()

          const mediaData = {
            title: data.mediaPopup.media.title,
            body: data.mediaPopup.content
              ? data.mediaPopup.content
              : data.mediaPopup.media.body,
            image: data.mediaPopup.media.urlMedia,
            isHtml: !!data.mediaPopup.content,
          }
          setPopupData(mediaData) // Set the fetched popup data
          setIsPopupVisible(true) // Show the popup

          // Set the current date in local storage
          localStorage.setItem('popupShown', currentDate.toISOString())
        } catch (error) {
          console.error('Failed to fetch popup data:', error)
        }
      }
      fetchPopupData()
    }
  }, [])

  const closePopup = () => {
    setIsPopupVisible(false)
  }

  return (
    <>
      {/* Render Popup if visible and data exists */}
      {isPopupVisible && popupData && (
        <Popup
          title={popupData.title}
          body={popupData.body}
          image={popupData.image}
          isHtml={popupData.isHtml}
          onClose={closePopup}
        />
      )}
      <HeroSection />
      <PresentationSection />
      <SectionDivider />
      <ActivitiesSection page={'home'} />
      <SectionDivider />
      <PortfolioSection />
      <SectionDivider />
      <PartnersSection />
      <NewsLetter />
      <EnvironmentalSection />
      <SectionDivider />
      <ContactSection page={'home'} />
    </>
  )
}

export default Home

//------------------
/* {isPopupVisible && (
        <Popup
          title={'test title'}
          body={'this is just for testing purpose'}
          image={'/images/habitation.jpg'}
          onClose={closePopup}
        />
      )} */
// useEffect(() => {
//   // Show popup on page load
//   setIsPopupVisible(true)
// }, [])
