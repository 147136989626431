import React from 'react';
import PropTypes from 'prop-types';

const Popup = ({ title, body, image, isHtml, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center  items-center z-50">
      <div className="bg-white shadow-lg w-3/4 rounded  relative">
        {/* Close Button */}
        <button
          className="absolute top-2 right-2 text-5xl text-white bg-red-600 rounded w-8 h-8 flex justify-center items-center hover:bg-red-800"
          onClick={onClose}
          aria-label="Close"
        >
          &times;
        </button>
        {/* Image */}
        {image && (
          <div>
            <img
              src={image}
              alt={title}
              className="w-full h-64 object-cover rounded-t"
            />
          </div>
        )}
        {/* Title */}
        <div className="p-4">
          <h2 className="text-xl font-bold text-red-600 mb-2">{title}</h2>
          {/* Body */}
          {isHtml ? (
            <div
              className="text-gray-600"
              dangerouslySetInnerHTML={{ __html: body }}
            ></div>
          ) : (
            <p className="text-gray-600">{body}</p>
          )}
        </div>
      </div>
    </div>
  );
};

Popup.propTypes = {
  title: PropTypes.string.isRequired,    // 'title' doit être une chaîne de caractères et est obligatoire
  body: PropTypes.string.isRequired,     // 'body' doit être une chaîne de caractères et est obligatoire
  image: PropTypes.string,               // 'image' est une chaîne de caractères mais est optionnel
  isHtml: PropTypes.bool,                // 'isHtml' doit être un booléen
  onClose: PropTypes.func.isRequired,    // 'onClose' doit être une fonction et est obligatoire
};

export default Popup;
