import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
 
import { FaWhatsapp } from 'react-icons/fa'
import Home from './pages/Home'
import About from './pages/About'
import Gallery from './pages/Gallery'
import Contact from './pages/Contact'
import Carousel from './pages/Carousel'
import Services from './pages/Services'
import Events from './pages/Events'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import { useEffect } from 'react'
import ScrollToTop from './components/ScrollToTop'

function App() {
  useEffect(() => {
    const registerVisit = async () => {
      try {                
        const response = await fetch(`${process.env.REACT_APP_API_URL}/visit`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        })
        if (!response.ok) {
          throw new Error('Failed to register visit')
        }
      } catch (error) {
        console.error('Failed to register visit:', error)
      }
    }

    registerVisit()
  }, [])
  return (
    <Router>
      <ScrollToTop></ScrollToTop>
      <div>
        <Navbar></Navbar>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/events" element={<Events />} />
          <Route path="/services" element={<Services />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/carousel" element={<Carousel />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer></Footer>
        {/* WhatsApp Icon */}
        <a
          href="https://wa.me/yourwhatsappnumber" // Replace with deltasign WhatsApp link
          target="_blank"
          rel="noopener noreferrer"
          className="fixed bottom-3 right-3 sm:hidden bg-green-500 text-white p-3 rounded-full shadow-sm shadow-green-100 hover:bg-green-600 transition-all"
        >
          <FaWhatsapp size={30} />
        </a>
      </div>
    </Router>
  )
}

export default App
