// import { Link } from 'react-router-dom'
// import Favicon from '../assets/images/fav-icon.png'
// function HeroSection() {
//   return (
//     <div className="relative flex flex-col lg:items-start items-center justify-center h-[90vh] w-full md:mt-0 mt-[-50px]">
//       {/* Background Video */}
//       <video
//         autoPlay
//         loop
//         muted
//         playsInline
//         className="absolute -z-40 top-0 left-0 w-full h-full object-cover"
//       >
//         <source src="/images/hero-bg1.mp4" type="video/mp4" />
//       </video>
//       <div className="absolute inset-0 -z-30 bg-gradient-to-b from-black/40 to-black/70 pointer-events-none"></div>

//       {/* Swimming Icons */}
//       <img
//         src={Favicon}
//         alt="Icon"
//         className="swimming-icon icon-1 h-14 w-16"
//       />
//       <img
//         src={Favicon}
//         alt="Icon"
//         className="swimming-icon icon-2 h-14 w-16"
//       />
//       <img
//         src={Favicon}
//         alt="Icon"
//         className="swimming-icon icon-3 h-14 w-16"
//       />
//       <img
//         src={Favicon}
//         alt="Icon"
//         className="swimming-icon icon-4 h-14 w-16"
//       />
//       <img
//         src={Favicon}
//         alt="Icon"
//         className="swimming-icon icon-5 h-14 w-16"
//       />
//       <img
//         src={Favicon}
//         alt="Icon"
//         className="swimming-icon icon-6 h-14 w-16"
//       />

//       {/* Hero Text */}
//       <div className="hero-content md:w-[40%] flex flex-col ml-4 lg:ml-[10%] px-[5%]">
//         <h1 className="text-[25px] md:text-[40px] font-bold text-white animate-fadeInDown mb-4 relative z-10">
//           <span className="font-title text-3xl md:text-5xl bg-clip-text">
//             DELT<span className="text-black">A</span>SIGN
//           </span>{' '}
//           revetement de sols, murs & plafonds
//         </h1>

//         <p className="text-lg md:text-xl text-white opacity-80 mt-2 animate-fadeInDown delay-200 relative z-10">
//           Depuis 9 ans,{' '}
//           <span className="text-white font-bold">
//             DELT<span className="text-black">A</span>SIGN
//           </span>{' '}
//           met à votre disposition une large palette de produits de revêtements
//           de sols, murs et plafonds.
//         </p>
//         <div className="flex flex-col w-full sm:w-auto sm:flex-row p-4">
//           <Link
//             to="/about"
//             className="flex flex-row items-center justify-center  w-full px-4 py-4 mb-4 text-sm font-bold bg-redPrimary leading-6 capitalize duration-100 transform rounded-sm shadow cursor-pointer focus:ring-4 focus:ring-red-200 focus:ring-opacity-50 focus:outline-none sm:mb-0 sm:w-auto sm:mr-4 md:pl-8 md:pr-6 xl:pl-12 xl:pr-10 hover:shadow-lg hover:-translate-y-1"
//           >
//             En savoir Plus
//             <span className="ml-4">
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 viewBox="0 0 24 24"
//                 className="w-5 h-5 fill-current"
//               >
//                 <path
//                   fill="currentColor"
//                   d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z"
//                 ></path>
//               </svg>
//             </span>
//           </Link>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default HeroSection

import { Link } from "react-router-dom";
import React from 'react';
import deltas from "../assets/images/4deltas.svg";

function HeroSection() {
  return (
    <div className="relative flex flex-col-reverse md:flex-row items-center justify-center  h-screen w-full bg-black text-white">
      {/* Hero Content */}
      <div className="relative z-20 flex flex-col justify-center px-6 py-8 md:border-l-4 border-white bg-redSecondary w-full md:w-[40%] h-auto text-center md:text-left md:-mr-12 md:rounded-md">
        {/* Background Delta Icons */}
        <div className="absolute inset-0 -z-10 opacity-80">
          <img
            src={deltas}
            alt="Deltas Icon"
            className="absolute -top-64 right-32 md:-top-20 md:-right-56 h-72 md:h-96"
          />
        </div>

        <h1 className="text-2xl md:text-3xl font-title font-bold">
          DELTASIGN,{" "}
        </h1>
        <p className="text-base opacity-90 md:text-lg font-title">
          Repoussez les limites de votre imagination.
        </p>
        <p className="text-sm opacity-80 md:text-base mt-2 font-title">
          Depuis 25 ans, Nous mettons à votre disposition une large palette de
          produits de revêtements de sols, murs et plafonds.
        </p>

        <div className="mt-6">
          <Link
            to="/about"
            className="px-4 py-2 md:px-6 md:py-3 bg-blackPrimary text-white font-bold rounded shadow hover:bg-redPrimary"
          >
            En savoir Plus
          </Link>
        </div>
      </div>

      {/* Right Side - Video */}
      <div className="relative z-10 w-full md:w-[60%] h-[100vh] md:h-[80%] mt-0">
        <video
          autoPlay
          loop
          muted
          playsInline
          className="w-full h-full object-cover md:rounded-lg "
        >
          <source src="/images/hero-bg1.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
  );
}

export default HeroSection;
