import React from 'react'
import PropTypes from 'prop-types';

function GridGallery({ images }) {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-20 sm:gap-6 lg:gap-8 w-full px-4 sm:px-[5%]">
      {images.map((image, index) => {
        const isEvenRow = Math.floor(index / 3) % 2 !== 0
        const colSpanClass = isEvenRow && index % 3 === 0 ? 'lg:col-span-2' : ''

        return (
          <div
            key={index}
            className={`relative group  cursor-pointer  sm:p-4 overflow-hidden ${colSpanClass}`}
          >
            <img
              src={image.src}
              alt={image.projectName}
              className="w-full h-[280px] lg:h-[320px] object-cover transition-transform transform group-hover:scale-110"
            />
            <div className="absolute bottom-[-20px] left-0 w-full h-1/4 bg-gradient-to-t from-black to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 ">
              <span className="absolute bottom-2 left-2  pb-3 text-white text-lg ">
                {image.projectName}
              </span>
            </div>
          </div>
        )
      })}
    </div>
  )
}
GridGallery.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,       // 'src' doit être une chaîne de caractères et est obligatoire
      projectName: PropTypes.string.isRequired // 'projectName' doit être une chaîne de caractères et est obligatoire
    })
  ).isRequired,  // 'images' doit être un tableau d'objets contenant les props spécifiées ci-dessus et est obligatoire
};

export default GridGallery
