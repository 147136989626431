import React, { useState } from 'react'
import PropTypes from 'prop-types';

import {
  FaEnvelope,
  FaFacebook,
  FaLinkedin,
  FaMapMarkerAlt,
  FaPhone,
  FaTwitter,
} from 'react-icons/fa'

function ContactSection({ page }) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  })

  const [loading, setLoading] = useState(false)
  const [feedback, setFeedback] = useState(null) // Feedback can hold either success or error message

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setFeedback(null)

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })

      if (response.ok) {
        setFeedback({ type: 'success', message: 'Message sent successfully!' })
        setFormData({ name: '', email: '', phone: '', message: '' })
      } else {
        const result = await response.json()
        setFeedback({
          type: 'error',
          message: result.message || 'Something went wrong.',
        })
      }
    } catch (err) {
      setFeedback({
        type: 'error',
        message: 'Failed to send message. Please try again.',
      })
    } finally {
      setLoading(false)

      // Clear feedback message after 3 seconds
      setTimeout(() => setFeedback(null), 3000)
    }
  }

  return (
    <>
      {page === 'home' && (
        <h1 className="text-center text-2xl sm:text-3xl font-title font-bold text-redPrimary w-full">
          Contact
        </h1>
      )}
      <div className="min-h-screen flex justify-center items-center p-8">
        <div className="flex flex-col sm:flex-row w-full max-w-6xl">
          {/* Left Side (Contact Info) */}
          <div className="sm:w-[40%] bg-white p-5 mb-8 sm:mb-0">
            <h2 className="sm:text-2xl text-xl text-center font-bold mb-6">
              Contact Information
            </h2>

            {/* Location */}
            <div className="flex items-center mb-6">
              <FaMapMarkerAlt className="text-redPrimary mb-3 sm:mb-0 text-2xl mr-6" />
              <ol className="list-disc">
                <li>3 rue Ambroise Croizat 95100 Argenteuil, France</li>
                <li>Zone d’activité Taharacht, Akbou, Algérie</li>
              </ol>
            </div>

            {/* Phone */}
            <div className="flex items-center mb-6">
              <FaPhone className="text-redPrimary text-2xl mr-6" />
              <ol className="list-disc">
                <li>+213 562 042 698</li>
                <li>+213 559 349 677</li>
              </ol>
            </div>

            {/* Email */}
            <div className="flex items-center mb-6">
              <FaEnvelope className="text-redPrimary text-2xl mr-6" />
              <a href="mailto:contact@deltasigngroup.com">
                contact@deltasigngroup.com
              </a>
            </div>
            <div className="flex  justify-center mb-2 sm:mb-8">
              <img
                src="/images/logo-dark.svg"
                alt="deltasign Logo"
                className=" w-[60%] h-40  object-cover"
              />
            </div>
            {/* Social Media Links */}
            <div className="flex justify-center mt-4 sm:mt-16 space-x-4">
              <a
                href="https://m.facebook.com/profile.php?id=100082684455788"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook
                  className="text-redPrimary hover:text-blue-800"
                  size={38}
                />
              </a>
              <a
                href="https://www.twitter.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitter
                  className="text-redPrimary hover:text-blue-600"
                  size={38}
                />
              </a>
              <a
                href="https://www.linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin
                  className="text-redPrimary hover:text-blue-900"
                  size={38}
                />
              </a>
            </div>
          </div>

          {/* Right Side (Contact Form) */}
          <div className="sm:w-[60%] p-6 bg-redSecondary sh-contact">
            <h2 className="text-2xl font-bold mb-6 text-white">
              Nous contacter
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="name" className="block text-gray-200 mb-2">
                  Votre Nom
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="w-full px-4 py-2 border rounded-lg"
                  required
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-4">
                <label htmlFor="email" className="block text-gray-200 mb-2">
                  Votre email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="w-full px-4 py-2 border rounded-lg"
                  required
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="phone" className="block text-gray-200 mb-2">
                  Numero telephone
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  className="w-full px-4 py-2 border rounded-lg"
                  required
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="message" className="block text-gray-200 mb-2">
                  Votre Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  className="w-full px-4 py-2 border rounded-lg"
                  rows="4"
                  required
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
              </div>

              <button
                type="submit"
                className="md:w-[30%] w-full bg-redPrimary text-white py-2 rounded-lg hover:bg-red-700"
                disabled={loading}
              >
                {loading ? 'Sending...' : 'Send Message'}
              </button>
            </form>
            {feedback && (
              <p
                className={`mt-4 ${
                  feedback.type === 'success'
                    ? 'text-green-500'
                    : 'text-red-300'
                }`}
              >
                {feedback.message}
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

// Validation des props
ContactSection.propTypes = {
  page: PropTypes.string,            // 'page' doit être une chaîne de caractères et est obligatoire
  formData: PropTypes.object,                             // 'formData' doit être un objet avec les propriétés spécifiées et est obligatoire
  handleSubmit: PropTypes.object,     // 'handleSubmit' doit être une fonction et est obligatoire
  handleChange: PropTypes.object,     // 'handleChange' doit être une fonction et est obligatoire
  loading: PropTypes.object,          // 'loading' doit être un booléen et est obligatoire
  feedback: PropTypes.shape({
    type: PropTypes.string.isRequired,         // 'type' dans feedback doit être une chaîne de caractères et est obligatoire
    message: PropTypes.string.isRequired,      // 'message' dans feedback doit être une chaîne de caractères et est obligatoire
  }),                                         // 'feedback' est un objet et ses propriétés doivent être spécifiées
};

export default ContactSection
