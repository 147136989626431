import { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import React from 'react';
function PresentationSection() {
  const cardRefs = useRef([])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry, index) => {
          if (entry.isIntersecting) {
            // Add the animation class to make the card visible
            entry.target.classList.add('fade-in')
            entry.target.style.animationDelay = `${index * 0.2}s` // Staggering animation
          }
        })
      },
      { threshold: 0.2 },
    )

    // Attach observer to each card
    cardRefs.current.forEach((card) => {
      if (card) observer.observe(card)
    })

    return () => observer.disconnect()
  }, [])

  return (
    <>
      <div className="flex items-center flex-col-reverse sm:flex-row justify-between bg-white h-[800px] sm:h-[700px] w-full border  border-red-500 rounded-md shadow-lg pb-24 sm:pb-32 px-[5%]  mx-auto">
        <div className="sm:w-1/2 w-full pr-10 mt-4">
          <div className="h-[2px] w-20 bg-redPrimary mb-4"></div>
          <h2 className="text-3xl font-bold font-title mb-4">Presentation</h2>
          <p className="text-gray-700 mb-6 border-l-[3px] pl-4 border-redPrimary">
            Depuis 25 ans,{' '}
            <span className="font-bold text-redPrimary">DELTASIGN </span>
            met à votre disposition une large palette de produits de revêtements
            de sols, murs et plafonds. Vous serez accompagné par notre équipe de
            professionnel afin de réaliser l’ensemble de vos projets, du plus
            sobre au plus audacieux
          </p>
          <Link to="/about">
            <button className="px-6 py-2 bg-redSecondary text-white  hover:bg-[#a44a4a] transition duration-200">
              Lire Plus
            </button>
          </Link>
        </div>
        {/* mt-[-50px] sm:mt-[-100px] : i didn't like it without this  */}
        <div className="sm:w-1/2 w-full h-full ">
          <img
            src="/images/presentation.jpg"
            alt="Presentation-Image"
            className="h-full w-full object-cover "
          />
        </div>
      </div>

      {/* Nos Valeurs Section */}
      <div className="text-center mt-5 sm:mt-[-100px]">
        <p className="text-2xl font-bold font-title text-redPrimary mb-6">
          Nos valeurs
        </p>
        <div className="flex flex-col md:flex-row items-center justify-center gap-6">
          {['QUALITE', 'REACTIVITE', 'RESPONSABILITE'].map((valeur, index) => (
            <div
              key={index}
              ref={(el) => (cardRefs.current[index] = el)}
              className="bg-white border border-gray-200 shadow-md rounded-lg p-6 w-60 text-center opacity-0 transform translate-y-[-20px]"
            >
              <p className="text-xl font-semibold text-redPrimary">{valeur}</p>
              <p className="text-gray-600 mt-2">
                {valeur === 'QUALITE' &&
                  'Un engagement envers la qualité exceptionnelle.'}
                {valeur === 'REACTIVITE' &&
                  'Une réponse rapide à vos besoins et demandes.'}
                {valeur === 'RESPONSABILITE' &&
                  'Une prise de responsabilité dans chaque projet.'}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default PresentationSection
