import React from 'react'
import ContactSection from '../components/ContactSection'
import { FaMapMarkerAlt } from 'react-icons/fa'
function Contact() {
  return (
    <>
      <div className="text-center my-6">
        <h2 className="text-2xl sm:text-3xl font-extrabold font-title text-white">
          CONTACT & <span className="text-redPrimary">INFOS</span>
        </h2>
      </div>
      <ContactSection />

      {/* Contact Content */}
      <h1 className="flex justify-center font-title text-white text-2xl md:text-3xl mt-10">
        <FaMapMarkerAlt className="mr-4  text-4xl text-redPrimary animate-bounce" />
        Vous nous trouverez ici
      </h1>
      <div className="flex flex-col md:flex-row  justify-between items-center w-[100%] mt-9   px-[5%]">
        <div className="iframe-container w-[90%] mx-auto m-4  animate-fadeInDown">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d25667.091284888993!2d4.522139175916183!3d36.47238211541807!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x128cfcd31570609b%3A0x34d4fd3b878dda46!2sTaharacht%2C%20Akbou!5e0!3m2!1sen!2sdz!4v1731058387605!5m2!1sen!2sdz"
            allowFullScreen=""
            loading="lazy"
            title="Location Map"
            className="iframe-3d w-full  mx-auto sm:w-[90%] h-[400px] "
          ></iframe>
          <h2 className="text-xl sm:text-2xl text-white mt-7 text-center">
            Akbou , Algérie
          </h2>
        </div>
        <div className="iframe-container w-[90%] mx-auto  m-4 animate-fadeInDown">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2620.2203347049513!2d2.212926876346016!3d48.949290271344616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e666a2ca4e6da7%3A0x2d17fbb02d731765!2s3%20Rue%20Ambroise%20Croizat%2C%2095100%20Argenteuil%2C%20France!5e0!3m2!1sen!2sdz!4v1731058889658!5m2!1sen!2sdz"
            allowFullScreen=""
            loading="lazy"
            title="Location Map"
            className="iframe-3d w-full sm:w-[90%] mx-auto h-[400px] "
          ></iframe>
          <h2 className="text-xl sm:text-2xl text-white mt-7 text-center">
            Argenteuil , France
          </h2>
        </div>
      </div>

      {/* Contact Section */}
    </>
  )
}

export default Contact
