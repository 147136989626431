// import React from 'react'
// import Slider from 'react-slick'
// import { FaArrowLeft, FaArrowRight, FaExternalLinkAlt } from 'react-icons/fa'
// import 'slick-carousel/slick/slick.css'
// import 'slick-carousel/slick/slick-theme.css'

// const CustomNextArrow = (props) => {
//   const { onClick } = props
//   return (
//     <button
//       onClick={onClick}
//       className="absolute right-4 top-1/2 transform -translate-y-1/2 z-10 text-red-600 hover:text-red-700"
//     >
//       <FaArrowRight size={20} />
//     </button>
//   )
// }

// const CustomPrevArrow = (props) => {
//   const { onClick } = props
//   return (
//     <button
//       onClick={onClick}
//       className="absolute left-4 top-1/2 transform -translate-y-1/2 z-10 text-red-600 hover:text-red-700"
//     >
//       <FaArrowLeft size={20} />
//     </button>
//   )
// }

// const Carousel = () => {
//   const medias = [
//     {
//       media: {
//         pseudo: 'test pseudo1',
//         title: 'test title1',
//         body: 'test body1',
//         urlMedia: '/images/habitation.jpg',
//         type: 'image',
//         showInCarousel: true,
//       },
//       url: 'test url1',
//     },
//     {
//       media: {
//         pseudo: 'test pseudo2',
//         title: 'test title2',
//         body: 'test body2',
//         urlMedia: '/images/hero-bg1.mp4',
//         type: 'video',
//         showInCarousel: true,
//       },
//       url: 'test url2',
//     },
//     {
//       media: {
//         pseudo: 'test pseudo3',
//         title: 'test title3',
//         body: 'test body3',
//         urlMedia: '/images/hotellerie.jpg',
//         type: 'image',
//         showInCarousel: true,
//       },
//       url: 'test url3',
//     },
//   ]

//   const settings = {
//     dots: false,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 5000,
//     nextArrow: <CustomNextArrow />,
//     prevArrow: <CustomPrevArrow />,
//   }

//   return (
//     <div className="w-full flex justify-center items-center pt-[-30px]  sm:py-10">
//       <div className="w-full max-w-[800px] h-[80vh] sm:h-[500px] relative">
//         <Slider {...settings}>
//           {medias.map((item, index) => (
//             <div key={index} className="relative w-full h-[600px] sm:h-[500px]">
//               {/* Media Display */}
//               {item.media.type === 'image' ? (
//                 <img
//                   src={item.media.urlMedia}
//                   alt={item.media.title}
//                   className="object-cover w-full h-full"
//                 />
//               ) : (
//                 <video
//                   src={item.media.urlMedia}
//                   autoPlay
//                   loop
//                   muted
//                   className="object-cover w-full h-full"
//                 />
//               )}

//               {/* Gradient Overlay */}
//               <div className="absolute inset-x-0 bottom-0 bg-gradient-to-t from-red-800 to-transparent w-full h-1/3"></div>

//               {/* Content - Title, Description, Button */}
//               <div className="absolute inset-x-0 bottom-0 p-4  flex justify-between items-end text-white z-10">
//                 <div>
//                   <h1 className="text-3xl font-bold mb-2">
//                     {item.media.title}
//                   </h1>
//                   <p className="text-lg ">{item.media.body}</p>
//                 </div>
//                 <a
//                   href={item.url}
//                   target="_blank"
//                   rel="noopener noreferrer"
//                   className="px-4 py-2 bg-red-600 hover:bg-red-700 rounded-lg text-white font-semibold"
//                 >
//                   <FaExternalLinkAlt size={20} />
//                 </a>
//               </div>
//             </div>
//           ))}
//         </Slider>
//       </div>
//     </div>
//   )
// }

// export default Carousel

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';

 
import { FaArrowLeft, FaArrowRight, FaExternalLinkAlt } from 'react-icons/fa'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

const CustomNextArrow = (props) => {
  const { onClick } = props
  return (
    <button
      onClick={onClick}
      className="absolute right-4 top-1/2 transform -translate-y-1/2 z-10 text-red-600 hover:text-red-700"
    >
      <FaArrowRight size={20} />
    </button>
  )
}

const CustomPrevArrow = (props) => {
  const { onClick } = props
  return (
    <button
      onClick={onClick}
      className="absolute left-4 top-1/2 transform -translate-y-1/2 z-10 text-red-600 hover:text-red-700"
    >
      <FaArrowLeft size={20} />
    </button>
  )
}

const Carousel = () => {
  const [medias, setMedias] = useState([])

  useEffect(() => {
    const fetchMedias = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/media/carousel`)
        if (!response.ok) {
          throw new Error('Failed to fetch media data')
        }
        const data = await response.json()
        setMedias(data.medias)
      } catch (error) {
        console.error('Error fetching media:', error)
      }
    }

    fetchMedias()
  }, [])

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    adaptiveHeight: true,
    fade: true,
  }

  return (
    <div className="w-full flex justify-center items-center pt-[-30px] sm:py-10">
      <div className="w-full max-w-[800px] h-[80vh] sm:h-[500px] relative">
        {!medias || medias.length === 0 ? (
          <div className="w-full h-full flex justify-center items-center text-xl text-gray-300">
            Currently, there are no media to display.
          </div>
        ) : (
          <Slider {...settings}>
            {medias.map((item, index) => {
              return (
                <div
                  key={item.id || index}
                  className="relative w-full h-[600px] sm:h-[500px]"
                >
                  {item.media.type === 'image' ? (
                    <img
                      src={item.media.urlMedia}
                      alt={item.media.title}
                      className="object-cover w-full h-full"
                    />
                  ) : (
                    <video
                      src={item.media.urlMedia}
                      autoPlay
                      loop
                      muted
                      className="object-cover w-full h-full"
                    />
                  )}
                  <div className="absolute inset-x-0 bottom-0 bg-gradient-to-t from-red-800 to-transparent w-full h-1/3" />
                  <div className="absolute inset-x-0 bottom-0 p-4 flex justify-between items-end text-white z-10">
                    <div>
                      <h1 className="text-3xl font-title font-bold mb-2">
                        {item.media.title}
                      </h1>
                      <p className="text-lg">{item.media.body}</p>
                    </div>
                    {item.url && (
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="px-4 py-2 bg-red-600 hover:bg-red-700 rounded-lg text-white font-semibold"
                      >
                        <FaExternalLinkAlt size={20} />
                      </a>
                    )}
                  </div>
                </div>
              )
            })}
          </Slider>
        )}
      </div>
    </div>
  )
};
CustomNextArrow.propTypes = {
  onClick: PropTypes.func,  // onClick est une fonction requise
}

CustomPrevArrow.propTypes = {
  onClick: PropTypes.func,  // onClick est une fonction requise
}

export default Carousel
