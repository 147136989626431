import '@fontsource/montserrat'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import '@fontsource/lato/400.css' // Regular weight
import '@fontsource/lato/700.css' // Bold weight

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
