import React from "react";
function ServiceContent() {
  return (
    <div className="flex bg-redSecondary justify-between flex-col-reverse   sm:flex-row w-full px-[5%] m-auto mt-[60px] py-10">
      {/* Left Side: Title, Description, and List */}
      <div className="text-white md:pr-8 w-full md:w-[40%]">
        <h2 className="text-4xl font-title font-bold mb-4 text-center sm:text-left">
          Nettoyage revêtements
        </h2>
        <p className="sm:text-lg sm:text-left  text-center text-whitePrimary mb-6  ">
          DELTASIGN propose un travail de qualité pour le nettoyage de vos
          revêtements de sols grâce à la formation assidue de son personnel
          ainsi qu’à l’utilisation de systèmes possédant les dernières
          technologies.{" "}
        </p>
        <ul className="list-none pl-3 space-y-2">
          <li>
            <strong>Détachage</strong>, <strong>rénovation</strong> et{" "}
            <strong>entretien</strong> des tapis, moquettes, tissus
            d’ameublements, revêtements muraux et rideaux.
          </li>
          <li>
            Nos solutions performantes et <strong>écologiques</strong>
            permettront de <strong>détruire</strong> les tâches les plus
            tenaces, les bactéries et d’imperméabiliser tous vos textiles.
          </li>
          <li>
            Contrat d’entretien ou intervention ponctuelle selon vos besoins
            avec une rapidité d’intervention
          </li>
        </ul>
      </div>

      {/* Right Side: Image Card */}
      <div className=" w-full mb-7  md:w-[50%]">
        <div className="mt-[-130px] p-4  shadow-lg bg-blackPrimary overflow-hidden">
          <img
            src="/images/service1.png"
            alt="Service Illustration"
            className="w-full h-auto rounded-sm"
          />
        </div>
      </div>
    </div>
  );
}

export default ServiceContent;
