import React, { useEffect, useRef, useState } from 'react'

function SectionDivider() {
  const triangleRef = useRef(null)
  const [flipped, setFlipped] = useState(false)

  useEffect(() => {
    let lastScrollY = window.scrollY

    const handleScroll = () => {
      const currentScrollY = window.scrollY

      // Flip down when scrolling down, and flip up when scrolling up
      setFlipped(currentScrollY > lastScrollY)
      lastScrollY = currentScrollY
    }

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll)

    // Cleanup on component unmount
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <div className="flex items-center justify-center my-14">
      {/* White Line on the Left */}
      <div className="h-[1px] bg-white flex-grow"></div>

      {/* Hollow Red Triangle with Flip Animation */}
      <div className="mx-5 flex items-center justify-center">
        <div
          ref={triangleRef}
          className={`w-0 h-0 border-l-[15px] border-r-[15px] border-b-[25px] border-transparent border-b-[#ba5a5b] relative transition-transform duration-300 ${
            flipped ? 'rotate-180' : 'rotate-0'
          }`}
        >
          {/* Inner White Triangle for Hollow Effect */}
          <div className="absolute top-[4px] left-[-12px] w-0 h-0 border-l-[12px] border-r-[12px] border-b-[20px] border-transparent border-b-white"></div>
        </div>
      </div>

      {/* White Line on the Right */}
      <div className="h-[1px] bg-white flex-grow"></div>
    </div>
  )
}

export default SectionDivider
