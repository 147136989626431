import React from 'react'
import { events } from '../constants/constant'
import Card from '../components/Card'
const Events = () => {
  return (
    <div className="mt-[5%]">
      <div className="px-4 py-10 font-sans">
        <div className="max-w-6xl max-lg:max-w-3xl max-sm:max-w-sm mx-auto">
          <div className="text-center">
            <h2 className="text-2xl sm:text-3xl font-extrabold font-title text-white">
              DERNIERS <span className="text-redPrimary">ÉVÉNEMENTS</span>
            </h2>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 max-sm:gap-8 mt-12">
            {events.map((event, index) => (
              <Card key={index} index={index} {...event} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Events
