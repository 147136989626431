import React from 'react'
import PropTypes from 'prop-types';

const Card = ({ index, image, date, author, title, description }) => {
  const isEven = index % 2 === 1 // Zero-based index: visually 1, 3, 5... are even positions
  return (
    <div
      className={`overflow-hidden border-b border-b-red-900 cursor-pointer p-4 ${
        isEven ? 'bg-redSecondary' : ''
      } hover:bg-redSecondary transition-all duration-300`}
    >
      <img
        src={image}
        alt={title}
        className="w-full h-64 object-cover rounded-md"
      />
      <div className="text-center">
        <span className="text-sm block text-white mb-2 mt-4">{`${date} | BY ${author}`}</span>
        <h3 className="text-xl font-bold text-white mb-4">{title}</h3>
        <p className="text-white text-sm">{description}</p>
      </div>
    </div>
  )
}

Card.propTypes = {
  index: PropTypes.number.isRequired,      // 'index' doit être un nombre et est obligatoire
  image: PropTypes.string.isRequired,      // 'image' doit être une chaîne de caractères et est obligatoire
  date: PropTypes.string.isRequired,       // 'date' doit être une chaîne de caractères et est obligatoire
  author: PropTypes.string.isRequired,     // 'author' doit être une chaîne de caractères et est obligatoire
  title: PropTypes.string.isRequired,      // 'title' doit être une chaîne de caractères et est obligatoire
  description: PropTypes.string.isRequired, // 'description' doit être une chaîne de caractères et est obligatoire
};

export default Card
