// Array of links and paths
export const navLinks = [
  { path: '/', label: 'Accueil' },
  { path: '/about', label: 'Présentation' },
  { path: '/events', label: 'Evénements ' },
  { path: '/services', label: 'Services' },
  { path: '/gallery', label: 'Galerie' },
  { path: '/carousel', label: 'Carousel' },
  { path: '/contact', label: 'Contact' },
]
export const imagesData = [
  {
    src: '/images/hbda1.jpg',
    projectName: 'Hotel bay Diab Alger',
    category: 'Hospitalité',
  },
  {
    src: '/images/hbda5.jpg',
    projectName: 'Hotel bay Diab Alger',
    category: 'Hospitalité',
  },
  {
    src: '/images/hbda7.jpg',
    projectName: 'Hotel bay Diab Alger',
    category: 'Hospitalité',
  },

  {
    src: '/images/haafa2.jpg',
    projectName: 'Hôtel Atlantis air de France Alger',
    category: 'Hospitalité',
  },
  {
    src: '/images/haafa3.jpg',
    projectName: 'Hôtel Atlantis air de France Alger',
    category: 'Hospitalité',
  },

  {
    src: '/images/hfpsa2.jpg',
    projectName: 'Hôtel FOURS POINT SETIF ALGÉRIE',
    category: 'Hospitalité',
  },

  {
    src: '/images/haaa1.jpg',
    projectName: 'Hôtel Atlantis Aures akbou',
    category: 'Hospitalité',
  },
  {
    src: '/images/haaa2.jpg',
    projectName: 'Hôtel Atlantis Aures akbou',
    category: 'Hospitalité',
  },
  {
    src: '/images/haaa3.jpg',
    projectName: 'Hôtel Atlantis Aures akbou',
    category: 'Hospitalité',
  },
  {
    src: '/images/ssu1.jpg',
    projectName: 'Siège social Unilever',
    category: 'Bureau et Commerce',
  },
  {
    src: '/images/ssu2.jpg',
    projectName: 'Siège social Unilever',
    category: 'Bureau et Commerce',
  },
  {
    src: '/images/ssu3.jpg',
    projectName: 'Siège social Unilever',
    category: 'Bureau et Commerce',
  },
  {
    src: '/images/ssu4.jpg',
    projectName: 'Siège social Unilever',
    category: 'Bureau et Commerce',
  },
  {
    src: '/images/pm1.jpg',
    projectName: 'salle de conférence park mall setif',
    category: 'Bureau et Commerce',
  },

  {
    src: '/images/pm4.jpg',
    projectName: 'salle de conférence park mall setif',
    category: 'Bureau et Commerce',
  },
  {
    src: '/images/ekaf1.jpg',
    projectName: 'Espace kids andilly France',
    category: 'Bureau et Commerce',
  },
  {
    src: '/images/ekaf2.jpg',
    projectName: 'Espace kids andilly France',
    category: 'Bureau et Commerce',
  },

  {
    src: '/images/srds2.jpg',
    projectName: 'Showroom Deltasign Alger',
    category: 'Bureau et Commerce',
  },

  {
    src: '/images/srds4.jpg',
    projectName: 'Showroom Deltasign Alger',
    category: 'Bureau et Commerce',
  },
  {
    src: '/images/habita1.jpg',
    projectName: 'Appartemment moderne tower ouled fayet',
    category: 'Habitation',
  },
  {
    src: '/images/habita2.jpg',
    projectName: 'Appartemment moderne tower ouled fayet',
    category: 'Habitation',
  },
  {
    src: '/images/habita3.jpg',
    projectName: 'Appartemment moderne tower ouled fayet',
    category: 'Habitation',
  },
  {
    src: '/images/habita4.jpg',
    projectName: 'Appartemment moderne tower ouled fayet',
    category: 'Habitation',
  },
  {
    src: '/images/habita5.jpg',
    projectName: 'Appartemment moderne tower ouled fayet',
    category: 'Habitation',
  },
  {
    src: '/images/msuiteh4.jpg',
    projectName: 'Msuite hôtel',
    category: 'Hospitalité',
  },
  {
    src: '/images/msuiteh1.jpg',
    projectName: 'Msuite hôtel',
    category: 'Hospitalité',
  },
  {
    src: '/images/msuiteh2.jpg',
    projectName: 'Msuite hôtel',
    category: 'Hospitalité',
  },
  {
    src: '/images/hac1.jpg',
    projectName: 'hôtel Aziz cheraga',
    category: 'Hospitalité',
  },
  {
    src: '/images/hac5.jpg',
    projectName: 'hôtel Aziz cheraga',
    category: 'Hospitalité',
  },

  {
    src: '/images/hac6.jpg',
    projectName: 'hôtel Aziz cheraga',
    category: 'Hospitalité',
  },
]
export const logos = [
  '/images/17.svg',
  '/images/18.svg',
  '/images/19.svg',
  '/images/20.svg',
  '/images/21.svg',
  '/images/22.svg',
  '/images/23.svg',
  '/images/24.svg',
  '/images/25.svg',
  '/images/26.svg',
  '/images/27.svg',
  '/images/28.svg',
  '/images/29.svg',
]

export const imagesM = [
  '/images/service2.png',
  '/images/service3.jpg',
  '/images/service4.jpg',
  '/images/service5.jpg',
  '/images/service6.jpg',
  '/images/service7.jpg',
]
export const imagesP = [
  '/images/service8.jpg',
  '/images/service9.jpg',
  '/images/service10.jpg',
  '/images/service11.jpg',
  '/images/service12.jpg',
  '/images/service13.jpg',
]

// Slick settings
export const settings = {
  infinite: true,
  slidesToShow: 3, // Number of images shown at once
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  centerMode: true, // This will add spacing between the images
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3, // 3 images shown on medium screens
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2, // 2 images on smaller screens
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1, // 1 image on very small screens
      },
    },
  ],
}
export const images = [
  '/images/1.svg',
  '/images/2.svg',
  '/images/3.svg',
  '/images/4.svg',
  '/images/5.svg',
  '/images/6.svg',
  '/images/7.svg',
  '/images/8.svg',
  '/images/9.svg',
  '/images/10.svg',
  '/images/11.svg',
  '/images/12.svg',
  '/images/13.svg',
  '/images/14.svg',
  '/images/15.svg',
  '/images/16.svg',
]

// Slick settingsEnv
export const settingsEnv = {
  infinite: true,
  slidesToShow: 4, // Number of images shown at once
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  centerMode: true, // This will add spacing between the images
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3, // 3 images shown on medium screens
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2, // 2 images on smaller screens (mobile)
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2, // 1 image on very small screens
      },
    },
  ],
}
export const events = [
  {
    image: '/images/about1.jpg',
    date: '10 FEB 2023',
    author: 'JOHN DOE',
    title: 'Igniting Your Imagination',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis accumsan, nunc et tempus blandit, metus mi consectetur felis turpis vitae ligula.',
  },
  {
    image: '/images/about1.jpg',
    date: '7 JUN 2023',
    author: 'MARK ADAIR',
    title: 'Hacks to Supercharge Your Day',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis accumsan, nunc et tempus blandit, metus mi consectetur felis turpis vitae ligula.',
  },
  {
    image: '/images/about1.jpg',
    date: '5 OCT 2023',
    author: 'SIMON KONECKI',
    title: 'Trends and Predictions',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis accumsan, nunc et tempus blandit, metus mi consectetur felis turpis vitae ligula.',
  },
]
