import React from 'react'

function Footer() {
  return (
    <div
      className="bg-white mt-10 shadow-md"
      style={{ boxShadow: '0 4px 6px rgba(199, 114, 116, 0.5)' }}
    >
      <div className="max-w-6xl mx-auto py-6 text-center">
        <p className="text-sm text-gray-600">
          Copyright © 2020 - 2024 | Group Deltasign, Partenaire web{' '}
          <a
            href="https://www.ay-web.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#c77274] hover:text-[#b05c56]"
          >
            AY WEB
          </a>
        </p>
      </div>
    </div>
  )
}

export default Footer
