import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'

function Breadcrumb({ onFilterSelect }) {
  const items = ['Habitation', 'Bureau et Commerce', 'Hospitalité']
  const location = useLocation() // Get the current URL location
  const [currentIndex, setCurrentIndex] = useState(0)
  const [selectedCategory, setSelectedCategory] = useState('Habitation')

  // Detect visible items based on window width
  const getVisibleItemsCount = () => {
    if (window.innerWidth >= 1024) return 4
    if (window.innerWidth >= 768) return 3
    return 2
  }

  const [visibleItemsCount, setVisibleItemsCount] = useState(
    getVisibleItemsCount,
  )

  useEffect(() => {
    const handleResize = () => setVisibleItemsCount(getVisibleItemsCount())
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    // Get the category from the URL query parameters
    const queryParams = new URLSearchParams(location.search)
    const categoryFromUrl = queryParams.get('category')
    if (categoryFromUrl) {
      setSelectedCategory(categoryFromUrl)
    }
  }, [location])

  const handleNext = () => {
    if (currentIndex < items.length - visibleItemsCount) {
      setCurrentIndex(currentIndex + 1)
    }
  }

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
    }
  }

  const handleCategorySelect = (item) => {
    setSelectedCategory(item)
    onFilterSelect(item)
  }

  return (
    <div className="flex items-center justify-center space-x-4 mb-14">
      <button
        onClick={handlePrev}
        className={`text-white hover:text-red-600 ${
          currentIndex === 0 && 'opacity-50 pointer-events-none'
        }`}
      >
        <FiChevronLeft size={24} />
      </button>
      <nav className="flex space-x-4">
        {items
          .slice(currentIndex, currentIndex + visibleItemsCount)
          .map((item) => (
            <button
              key={item}
              onClick={() => handleCategorySelect(item)}
              className={`transition-colors font-medium ${
                selectedCategory === item
                  ? 'text-red-600 text-lg font-bold'
                  : 'text-white hover:text-red-600'
              }`}
            >
              {item}
            </button>
          ))}
      </nav>
      <button
        onClick={handleNext}
        className={`text-white hover:text-red-600 ${
          currentIndex >= items.length - visibleItemsCount &&
          'opacity-50 pointer-events-none'
        }`}
      >
        <FiChevronRight size={24} />
      </button>
    </div>
  )
}

Breadcrumb.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string), // 'items' doit être un tableau de chaînes de caractères et est requis
  currentIndex: PropTypes.number, // 'currentIndex' doit être un nombre et est requis
  visibleItemsCount: PropTypes.number, // 'visibleItemsCount' doit être un nombre et est requis
  selectedCategory: PropTypes.string, // 'selectedCategory' doit être une chaîne de caractères et est requis
  handlePrev: PropTypes.func, // 'handlePrev' doit être une fonction et est requise
  handleNext: PropTypes.func, // 'handleNext' doit être une fonction et est requise
  handleCategorySelect: PropTypes.func, // 'handleCategorySelect' doit être une fonction et est requise
  onFilterSelect: PropTypes.func,
};

export default Breadcrumb
