import { Link } from 'react-router-dom'
import { FiArrowUpRight } from 'react-icons/fi'
import React from 'react';
import PropTypes from 'prop-types';

function AboutContent({ text, imgUrl, reverse }) {
  return (
    <div
      className={`mx-auto flex flex-col md:flex-row w-[90%] md:w-[85%] md:h-[600px] justify-center px-4 pb-12 pt-8 md:pb-24 md:pt-12 ${
        reverse ? 'md:flex-row-reverse' : ''
      } `}
    >
      <div
        className={`w-full md:w-[30%] h-64 mb-5 md:mb-0 md:h-auto ${
          reverse ? '' : 'md:mr-4 lg:mr-8'
        }`}
      >
        <img
          src={imgUrl}
          alt="Example Content"
          className="h-full w-full object-cover"
        />
      </div>
      <div
        className={`flex flex-col justify-center bg-redSecondary p-4 md:text-justify self-stretch h-64 md:h-auto md:min-h-full w-full md:w-[60%] ${
          reverse ? 'md:border-r md:mr-4 lg:mr-8' : 'md:border-l'
        }`}
      >
        <p className="mb-4 text-base text-whitePrimary md:text-lg lg:text-xl">
          {text}
        </p>
        <Link
          to="/carousel"
          className="mt-4 w-[80%] mx-auto rounded bg-neutral-900 px-6 py-3 text-center text-sm text-white transition-colors hover:bg-neutral-700 md:w-auto md:px-9 md:py-4 md:text-lg"
        >
          En Savoir Plus <FiArrowUpRight className="inline" />
        </Link>
      </div>
    </div>
  )
}
AboutContent.propTypes = {
  text: PropTypes.string.isRequired,    // 'text' doit être une chaîne de caractères et est obligatoire
  imgUrl: PropTypes.string.isRequired,  // 'imgUrl' doit être une chaîne de caractères et est obligatoire
  reverse: PropTypes.bool,              // 'reverse' est une valeur booléenne et est optionnelle
};

export default AboutContent
