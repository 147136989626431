import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Breadcrumb from '../components/Breadcrumb'
import GridGallery from '../components/GridGallery'
import { imagesData } from '../constants/constant'

function Gallery() {
  const location = useLocation()
  const [filter, setFilter] = useState('Habitation')

  useEffect(() => {
    // Extract the category from the query params
    const queryParams = new URLSearchParams(location.search)
    const categoryFromUrl = queryParams.get('category')
    if (categoryFromUrl) {
      setFilter(categoryFromUrl)
    }
  }, [location])

  const handleFilterSelect = (selectedFilter) => {
    setFilter(selectedFilter)
  }

  // Filter images based on selected category
  const filteredImages = filter
    ? imagesData.filter((image) => image.category === filter)
    : imagesData

  return (
    <div className="min-h-screen flex flex-col items-center">
      <h1 className="text-3xl font-title font-bold text-white my-14 text-center">
        Notre travail dans divers domaines
      </h1>
      <Breadcrumb onFilterSelect={handleFilterSelect} />
      <GridGallery images={filteredImages} />
    </div>
  )
}

export default Gallery
