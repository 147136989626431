import React, { useState } from 'react'

function NewsLetter() {
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState(null) // For success/error messages

  const handleSubmit = async (e) => {
    e.preventDefault()
    setMessage(null)

    if (!email.trim()) {
      setMessage('Veuillez saisir un email valide.')
      return
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/subscriber`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      })

      if (response.ok) {
        setMessage('Merci pour votre inscription à la newsletter !')
        setEmail('') // Clear the input
      } else {
        const errorData = await response.json()
        setMessage(errorData.message || 'Une erreur est survenue.')
      }
    } catch (error) {
      setMessage("Impossible de s'inscrire. Veuillez réessayer plus tard.")
      console.error('Error:', error)
    }
  }

  return (
    <div className="bg-redSecondary ">
      <div className="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:py-16 lg:px-8">
        <div className="px-6 py-6 rounded-lg md:py-12 md:px-12 lg:py-16 lg:px-16 xl:flex xl:items-center">
          <div className="xl:w-0 xl:flex-1">
            <h2 className="text-3xl font-bold leading-8 tracking-tight text-white sm:text-4xl sm:leading-9">
              Soyez informé chaque fois que nous publions quelque chose de
              nouveau
            </h2>
            <p
              className="max-w-3xl mt-3 text-lg leading-6 text-indigo-200"
              id="newsletter-headline"
            >
              Inscrivez-vous à notre newsletter pour rester au courant.
            </p>
          </div>
          <div className="mt-8 sm:w-full sm:max-w-md xl:mt-0 xl:ml-8">
            <form
              onSubmit={handleSubmit}
              className="sm:flex"
              aria-labelledby="newsletter-headline"
            >
              <input
                aria-label="Email address"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full px-5 py-3 text-base leading-6 text-gray-900 placeholder-gray-500 transition duration-150 ease-in-out bg-white border border-transparent rounded-md appearance-none focus:outline-none focus:placeholder-gray-400"
                placeholder="Enter your email"
              />
              <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <button
                  type="submit"
                  className="flex items-center justify-center w-full px-5 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-redPrimary border border-transparent rounded-md hover:bg-[#e6b4b5] focus:outline-none focus:bg-[#e6b4b5]"
                >
                  Envoyer
                </button>
              </div>
            </form>
            {message && (
              <p className="mt-3 text-sm leading-5 text-indigo-200">
                {message}
              </p>
            )}
            <p className="mt-3 text-sm leading-5 text-indigo-200">
              Nous ne ferons jamais de spam. C’est notre promesse.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewsLetter
